
  import Vue from "vue";
  import pageMixin from "@/mixins/page";
  import InfiniteLoading, {StateChanger} from "vue-infinite-loading";
  import {IMAGES_SEGMENTS} from "@/utils/images";
  import { BreadcrumbItems, BreadcrumbItemType } from '@/utils/breadcrumb';
  import { IDENTITY_VERIFICATION_TYPES } from '@/utils/transactions';

  const prizesLimitMobile = 10;
  const prizesLimit = 60;

  export default Vue.extend({
    name: "LastPrizesDistributed",
    mixins: [pageMixin],
    components: {
      InfiniteLoading,
      PwaSlider: () => import('@/components/common/PwaSlider.vue'),
      PwaIcon: () => import('@/components/common/PwaIcon.vue'),
      PwaWebAdHalfPageBanner: () => import('@/components/ads/PwaWebAdHalfPageBanner.vue'),
      PwaWebAdLeaderboardBanner: () => import('@/components/ads/PwaWebAdLeaderboardBanner.vue'),
      PwaWebAdLargeMobileBanner: () => import('@/components/ads/PwaWebAdLargeMobileBanner.vue'),
      PwaWebAdInlineRectangleBanner: () => import('@/components/ads/PwaWebAdInlineRectangleBanner.vue'),
    },
    data() {
      return {
        imagesSegments: IMAGES_SEGMENTS,
        prizesDistributedList: [] as { username: string; avatar: string; user_id: number; transaction_date: string; withdraw_coin: { amount: number; currency: string; parent_description: string; icon: string; description: string };}[],
        hasMorePrizesDistributed: false,
        identityVerificationTypes: IDENTITY_VERIFICATION_TYPES,
        breadcrumb: [{labelId: BreadcrumbItems.lastPrizesDistributed.labelId}] as BreadcrumbItemType[],
      }
    },
    mounted(): void {
      this.getLastPrizesDistributedPage();
    },
    computed: {
      prizesLimit(): number {
        return this.isMobile ? prizesLimitMobile : prizesLimit;
      },
      prizesListOrganized(): any[] {
        if (this.isMobile) {
          return this.prizesDistributedList;
        } else {
          const numRows = 4;
          const list = [];
          const totalPrizesDistributed = this.prizesDistributedList.length;
          if (totalPrizesDistributed > 0) {
            const prizesByRow = Math.floor(totalPrizesDistributed / numRows);
            let lastEnd = 0;
            for (let i = 1; i <= numRows; i++) {
              const nextEnd = lastEnd + prizesByRow;
              const end = i < numRows
                ? nextEnd
                : totalPrizesDistributed;
              list.push(this.prizesDistributedList.slice(lastEnd, end));
              lastEnd = end;
            }
          }

          return list;
        }
      },
    },
    methods: {
      async getLastPrizesDistributedPage($state: StateChanger = null): Promise<void> {
        if (!$state) {
          await this.showLoader(true);
        }
        try {
          const offset = this.prizesDistributedList.length;
          const {data} = await this.$http.page.getLastPrizesDistributedPage(offset, this.prizesLimit);
          const prizesDistributedList = data.data || [];
          if (offset > 0) {
            this.prizesDistributedList = [...this.prizesDistributedList, ...prizesDistributedList];
          } else {
            this.prizesDistributedList = prizesDistributedList;
          }

          this.hasMorePrizesDistributed = this.isMobile ? (prizesDistributedList.length >= this.prizesLimit) : false;
        } catch (e) {
          this.showToastError(this.$t('139'), this.$t('140'));
        } finally {
          if ($state) {
            $state.loaded();
          } else {
            await this.showLoader(false);
          }
        }
      }
    },
    watch: {
      prizesLimit() {
        this.prizesDistributedList = [];
        this.hasMorePrizesDistributed = false;
        this.getLastPrizesDistributedPage();
      }
    }
  });
